import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "../../utils/Axios";
import { useAlert } from "react-alert";
import { ListItem, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export default function CustomeFieldDialogEdit({ custom_id,saahe_id, type, customList }) {
  const alert = useAlert();
  const [open, setOpen] = React.useState(false);
  const [edit, setedits] = React.useState({});

  const handleClickOpen = () => {
    setOpen(true);
    if (type == 1) {
    axios(`Home/get_custom_fields_info?id=${custom_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
          alert.error(res.data.message);
        } else {
          setedits(res.data.data[0]);
        }
      })
      .catch((err) => console.log(err));
    }
    else {
      axios(`Course/get_custom_fields_info?id=${custom_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error === true) {
            alert.error(res.data.message);
          } else {
            setedits(res.data.data[0]);
          }
        })
        .catch((err) => console.log(err));
      }

      
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updatecustom = () => {
    let formdata = new FormData();
    if (type == 1) {
      formdata.append("custom_id", custom_id);
      formdata.append("title", edit.title);
      formdata.append("description", edit.description);
      axios(`Home/update_custom_fields`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            alert.success(res.data.message);
            customList(saahe_id);
            setOpen(false);
          }
        })
        .catch((err) => console.log(err));
    } else {
      formdata.append("custom_id", custom_id);
      formdata.append("title", edit.title);
      formdata.append("description", edit.description);
      axios(`Course/update_custom_fields`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            alert.success(res.data.message);
            customList(saahe_id);
            setOpen(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <div>
      <ListItem
        onClick={handleClickOpen}
        secondaryAction={
          <IconButton edge="end" aria-label="delete">
            <EditIcon />
          </IconButton>
        }
      ></ListItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Custome Field </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit Custome Field menu and description here, and then click save.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            placeholder="New Field Name"
            fullWidth
            variant="standard"
            value={edit.title}
                onChange={(e) => {
                  setedits({ ...edit, title: e.target.value });
                }}
          />
          <TextField
            multiline
            minRows={4}
            margin="dense"
            id="Description"
            placeholder="New Field Description"
            fullWidth
            variant="standard"
            value={edit.description}
                onChange={(e) => {
                  setedits({ ...edit, description: e.target.value });
                }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={updatecustom}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
