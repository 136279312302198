import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import { experimentalStyled as styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export default function DetailAccordionReviews({ saaheId,getrating,ratings,total }) {

  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    getrating(saaheId,page,rowsPerPage);
  }, [page, rowsPerPage]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      {ratings && ratings.map((item) => {
           return (
             <div>
      <Box
        sx={{
          width: 290,
          display: "flex",
          alignItems: "center",
          px: 2,
        }}
      >
        <Rating
        readOnly
          name="hover-feedback"
          value={item.rating}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />
        
      </Box>

      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        
          <Grid item xs={2} sm={4} md={6} key={1}>
            <Box
              sx={{
                my: 1,
                mx: "auto",
                p: 2,
              }}
            >
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <Avatar
                    alt="NAME"
                    src="https://jobportal.gligx.com/img/saaeh/logo-icon.webp"
                    sx={{ width: 56, height: 56 }}
                  />
                </Grid>
                <Grid item xs>
                  <Typography fontWeight={600}>
                    {item.title}
                  </Typography>
                  <Typography>{item.review}</Typography>
                  <Typography color="GrayText">By {item.user} ({item.added_date})</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
      </Grid>
      </div>
      )
    })}
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 12, sm: 12, md: 12 }}
      >
        <Grid item xs>
          <Stack spacing={2} sx={{ alignItems: "center"}}>
            <Pagination count={total} page={page} onChange={handleChange} color="primary" />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
