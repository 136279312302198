import { Grid, IconButton, ListItem } from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "../utils/Axios"; //import axios config
import { useAlert } from "react-alert";

export default function DeleteConfirmDialog({type,id,Lists}) {

    const alert = useAlert();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };


     const handleDelete = () => {
      
      setLoading(true);
      axios(`Settings/delete_${type}?id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
            setLoading(false);
          } else {
            alert.success(res.data.message);
            setLoading(false);
            setOpen(false);
            Lists();
          }
        })
        .catch((err) => console.log(err));
  
    }; 

  return (
    <div>
      <ListItem
        secondaryAction={
          <IconButton onClick={handleClickOpen} edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>
        }
      ></ListItem>
       
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          This will permanently delete.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button onClick={handleDelete} autoFocus>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
