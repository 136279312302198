import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import StarIcon from "@mui/icons-material/Star";
import { Box, Rating, Typography } from "@mui/material";
import axios from "../../utils/Axios";
import { useAlert } from "react-alert";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

export default function ExpertDetailDialog({
  saaheId,
  ratingvalue,
  rateType,
  rating_count,
  getProfile,
  getrating,
}) {
  const [value, setValue] = React.useState(0);
  const [rating, setRating] = React.useState();
  const [hover, setHover] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [title, set_title] = React.useState("");
  const [desc, set_desc] = React.useState("");
  const alert = useAlert();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addRating = (saaheId) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();

      formdata.append("rating", rating);
      formdata.append("title", title);
      formdata.append("description", desc);
      if (rateType === 1) {
        formdata.append("Id", saaheId);
        axios(`Saahe/saahe_rate`, {
          method: "POST",
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
            "x-api-key": JSON.parse(localStorage.getItem("data")),
          },
        })
          .then((res) => {
            if (res.data.error) {
              alert.error(res.data.message);
            } else {
              alert.success(res.data.message);
              getProfile();
              getrating(saaheId, 1, 10);
              setOpen(false);
            }
          })
          .catch((err) => console.log(err));
      } else {
        formdata.append("storeId", saaheId);
        axios(`Bookstore/rate_bookstore`, {
          method: "POST",
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
            "x-api-key": JSON.parse(localStorage.getItem("data")),
          },
        })
          .then((res) => {
            if (res.data.error) {
              alert.error(res.data.message);
            } else {
              alert.success(res.data.message);
              getProfile();
              setOpen(false);
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const getsaaherating = () => {
    if (rateType === 1) {
      axios(`Saahe/get_user_ratings?Id=${saaheId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
          } else {
            set_title(res.data.data[0].title);
            setRating(res.data.data[0].rating);
            set_desc(res.data.data[0].review);
          }
        })
        .catch((err) => console.log(err));
    } else {
      axios(`Bookstore/get_user_store_rating?storeId=${saaheId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
          } else {
            set_title(res.data.data[0].title);
            setRating(res.data.data[0].rating);
            set_desc(res.data.data[0].review);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  React.useEffect(() => {
    getProfile();
    getsaaherating();
  }, [saaheId]);

  return (
    <div className="MyAccountProfileBg">
      <Box
        onClick={handleClickOpen}
        className="star_rating_box"
        display="flex"
        sx={{
          "& > legend": { pb: 5 },
          cursor: "pointer",
        }}
      >
        <Typography component="legend" color="white"></Typography>
        <Rating
          name="half-rating"
          readOnly
          value={ratingvalue}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
        />{" "}
        <span className="education_detail_rating_count">
          {ratingvalue} ({rating_count})
        </span>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Write a Customer Comment and Rating </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please write a comment for this Institution. We will use this to
            improve our service.
          </DialogContentText>
          <Box
            sx={{
              width: 200,
              display: "flex",
              alignItems: "center",
              my: 2,
            }}
          >
            <Rating
              name="hover-feedback"
              value={rating}
              precision={0.5}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            {rating !== null && (
              <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : rating]}</Box>
            )}
          </Box>
          <TextField
            label="Title"
            variant="standard"
            fullWidth
            value={title}
            onChange={(e) => set_title(e.target.value)}
          />
          <TextField
            autoFocus
            multiline
            maxRows={4}
            margin="dense"
            id="name"
            label="Add your review here"
            type="text"
            fullWidth
            variant="standard"
            value={desc}
            onChange={(e) => set_desc(e.target.value)}
          />
        </DialogContent>
        <DialogActions className="MyAccountProfileBg">
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            className="primaryButton"
            variant="contained"
            onClick={() => addRating(saaheId)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
