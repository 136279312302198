import {Box,Container,Grid,styled,Stack,Typography,Button,CardMedia,CardContent,CardActions,
  ThemeProvider,} from "@mui/material";
import React from "react";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ButtonBase from "@mui/material/ButtonBase";
import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import LoadingOverlay from "react-loading-overlay";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../utils/Axios"; //import axios config
import parse from "html-react-parser";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#101010" : "grey.100",
        color: (theme) =>
          theme.palette.mode === "dark" ? "grey.300" : "grey.800",
        border: "1px solid",
        borderTop: "8px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "grey.800" : "grey.300",
        borderRadius: 2,
        borderTopColor: "#1E3A57",
        fontSize: "0.875rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function ItemHead(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#101010" : "grey.100",
        color: (theme) =>
          theme.palette.mode === "dark" ? "grey.300" : "grey.800",
        border: "1px solid",
        borderLeft: "8px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "grey.800" : "grey.300",
        borderRadius: 2,
        borderLeftColor: "#1E3A57",
        fontSize: "0.875rem",
        fontWeight: "bold",
        ...sx,
      }}
      {...other}
    />
  );
}
ItemHead.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default function ViewAllStories() {
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [category, setCategory] = React.useState([]);
  const [news, setNews] = React.useState([]);
  const [popularnews, setPopularNews] = React.useState([]);
  const [second_news, setsecondNews] = React.useState([]);
  const [last, setlast] = React.useState([]);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  React.useEffect(() => {
    categoryList();
    newsList();
    popularList(4);
    second_newsList(8);
    window.scrollTo(0, 0);
  }, []);

  const categoryList = () => {
    setLoading(true);
    axios(`Article/get_news_categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCategory(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const ArticleView = (id) => {
    history({
      pathname: `/StoriesDetail/${id}`,
    });
    window.location.reload();
    window.scrollTo(0, 0);
  };

  const newsList = () => {
    setLoading(true);
    axios(`Article/get_all_news`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setNews(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const popularList = () => {
    setLoading(true);
    axios(`Article/get_popular_news`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setPopularNews(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const second_newsList = (category_id) => {
    setLoading(true);
    axios(`Article/get_news_category_byid?category_id=${category_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setsecondNews(res.data.data); //to set response data to state
          setlast(res.data.data[0].lastdata[0]);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <Header />
      <ThemeProvider theme={theme}>
        <Box
          className="caro_bg"
          sx={{ bgcolor: "#fff", pt: "70px", pb: "48px" }}
        >
          <Container maxWidth="xl">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="h3"
                      textAlign="center"
                      color="#1E3A57"
                      fontWeight={600}
                      component="h3"
                      gutterBottom
                      mt={3}
                    >
                    Aqar Stories
                    </Typography>
                    <Stack
                      spacing={2}
                      direction="row"
                      flexWrap="wrap"
                      justifyContent="center"
                      color="#1E3A57"
                    >
                      <Link to="/EducationGuideArticlesLists/0">
                        <Button variant="text" className="newsbtn">
                          Latest News
                        </Button>
                      </Link>
                      {category.map((value, index) => {
                        return (
                          <Button
                            color="secondary"
                            variant="text"
                            onClick={() => ArticleView(value.id)}
                          >
                            {value.title}
                          </Button>
                        );
                      })}
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <LoadingOverlay
                      active={loading}
                      styles={{
                        overlay: (base) => ({
                          ...base,
                          background: "rgba(255, 255, 255, 1.9)",
                        }),
                        spinner: (base) => ({
                          ...base,
                          width: "75px",
                          "& svg circle": {
                            stroke: "#025b95",
                          },
                        }),
                      }}
                      spinner
                      text="Loading ..."
                    >
                      <Item>
                        <CardContent>
                          <CardMedia
                            sx={{
                              borderRadius: 1,
                              border: "1px solid #f5f5f5",
                            }}
                            component="img"
                            alt="green iguana"
                            image={news[0] && news[0].images}
                          />
                        </CardContent>
                        <CardContent>
                          <Typography
                            nowrap
                            gutterBottom
                            variant="h5"
                            component="div"
                          >
                            {news[0] && news[0].title}
                          </Typography>
                          <Typography
                            nowrap
                            variant="body2"
                            color="text.secondary"
                          >
                            {news[0] &&
                              parse(
                                news[0].description.substring(0, 20) + "..."
                              )}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button size="small">
                            Paperback – {news[0] && news[0].created}
                          </Button>
                          <Link
                            to={`/StoriesDetail/${
                              news[0] && news[0].id
                            }`}
                          >
                            <Button size="small">More...</Button>
                          </Link>
                        </CardActions>
                      </Item>
                    </LoadingOverlay>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Grid item xs={12} md={12}>
                      <Item>
                        <CardContent>
                          <AutoPlaySwipeableViews
                            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                          >
                            {news[0] &&
                              news[0].datas.map((step, index) => (
                                <div key={step.title}>
                                  {Math.abs(activeStep - index) <= 2 ? (
                                    <Link
                                      to={`/StoriesDetail/${step.id}`}
                                    >
                                      <Box
                                        component="img"
                                        sx={{
                                          height: "auto",
                                          maxHeight: "300px",
                                          display: "block",
                                          overflow: "hidden",
                                          width: "100%",
                                          borderRadius: 1,
                                        }}
                                        src={step.images}
                                        alt={step.title}
                                      />
                                    </Link>
                                  ) : null}
                                </div>
                              ))}
                          </AutoPlaySwipeableViews>
                          <Typography>
                            {news[0] && news[0].datas[activeStep].title}
                          </Typography>
                        </CardContent>
                      </Item>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Item>
                          <LoadingOverlay
                            active={loading}
                            styles={{
                              overlay: (base) => ({
                                ...base,
                                background: "rgba(255, 255, 255, 1.9)",
                              }),
                              spinner: (base) => ({
                                ...base,
                                width: "75px",
                                "& svg circle": {
                                  stroke: "#025b95",
                                },
                              }),
                            }}
                            spinner
                            text="Loading ..."
                          >
                            <Carousel
                              autoPlay={true}
                              showArrows={false}
                              showIndicators={false}
                              showStatus={false}
                              showThumbs={false}
                              infiniteLoop={true}
                              stopOnHover={false}
                              axis="vertical"
                            >
                              {news[0] &&
                                news[0].datas.map((data, index) => (
                                  <Box key={index} sx={{ py: "16px" }}>
                                    <Typography gutterBottom>
                                      <b>{data.title}</b>
                                    </Typography>
                                  </Box>
                                ))}
                            </Carousel>
                            {news[0] &&
                              news[0].datas.map((data, index) => (
                                <Grid container spacing={1} mb={0} key={index}>
                                  <Grid item xs={12} sm container>
                                    <CardContent>
                                      <Grid item>
                                        <Link
                                          to={`/StoriesDetail/${data.id}`}
                                        >
                                          <ButtonBase
                                            sx={{ width: 100, height: 68 }}
                                          >
                                            <Img
                                              alt="complex"
                                              src={data.images}
                                              sx={{
                                                borderRadius: 1,
                                                border: "1px solid #f5f5f5",
                                              }}
                                            />
                                          </ButtonBase>
                                        </Link>
                                      </Grid>
                                    </CardContent>
                                    <Grid item xs={12} sm container>
                                      <Link
                                        to={`/StoriesDetail/${data.id}`}
                                      >
                                        <Grid item xs>
                                          <CardContent sx={{ px: "0px" }}>
                                            <Typography
                                              variant="subtitle1"
                                              component="div"
                                              fontWeight={600}
                                            >
                                              {parse(
                                                data.title.substring(0, 35) +
                                                  "..."
                                              )}
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              color="textSecondary"
                                              component="p"
                                            >
                                              {parse(
                                                data.short_description.substring(
                                                  0,
                                                  35
                                                ) + "..."
                                              )}
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              color="textSecondary"
                                            >
                                              Paperback – {data.created}
                                            </Typography>
                                          </CardContent>
                                        </Grid>
                                      </Link>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                          </LoadingOverlay>
                        </Item>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box>
                    <Grid
                      container
                      spacing={{ xs: 1, md: 1 }}
                      columns={{ xs: 4, sm: 12, md: 12 }}
                    >
                      <Grid item xs={12}>
                        <ItemHead>
                          <Typography
                            pl={1}
                            gutterBottom
                            variant="h5"
                            fontWeight={500}
                            color="#1E3A57"
                            component="div"
                          >
                            Most Popular
                          </Typography>
                        </ItemHead>
                      </Grid>
                      {popularnews.map((data, index) => (
                        <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                          <Item>
                            <CardContent>
                              <Box sx={{ display: "flex" }}>
                                <Link
                                  to={`/StoriesDetail/${data.id}`}
                                >
                                  <Grid item>
                                    <ButtonBase
                                      sx={{ width: 230, height: 153 }}
                                    >
                                      <Img
                                        alt="complex"
                                        src={data.images}
                                        sx={{
                                          borderRadius: 1,
                                          border: "1px solid #f5f5f5",
                                        }}
                                      />
                                    </ButtonBase>
                                  </Grid>
                                </Link>
                                <Link
                                  to={`/StoriesDetail/${data.id}`}
                                >
                                  <Grid item xs={12} sm container>
                                    <Grid item xs>
                                      <CardContent
                                        sx={{
                                          py: "0px",
                                          px: "0px",
                                          pl: "16px",
                                        }}
                                      >
                                        <Typography
                                          gutterBottom
                                          variant="subtitle1"
                                          component="div"
                                          fontWeight={600}
                                        >
                                          {parse(
                                            data.title.substring(0, 35) + "..."
                                          )}
                                        </Typography>

                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                        >
                                          Paperback – {data.created}
                                        </Typography>
                                        <Typography
                                          variant="p"
                                          color="textSecondary"
                                          fontWeight={600}
                                        >
                                          {parse(
                                            data.description.substring(0, 70)
                                          )}
                                        </Typography>
                                      </CardContent>
                                    </Grid>
                                  </Grid>
                                </Link>
                              </Box>
                            </CardContent>
                          </Item>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Grid item xs={12}>
                    <ItemHead>
                      <Typography
                        pl={1}
                        gutterBottom
                        variant="h5"
                        fontWeight={500}
                        color="#1E3A57"
                        component="div"
                      >
                        TOP STORIES
                      </Typography>
                    </ItemHead>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Item>
                      <CardContent>
                        <AutoPlaySwipeableViews
                          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                          index={activeStep}
                          onChangeIndex={handleStepChange}
                          enableMouseEvents
                        >
                          {news[0] &&
                            news[0].datas.map((step, index) => (
                              <div key={step.title}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                  <Link
                                    to={`/StoriesDetail/${step.id}`}
                                  >
                                    <Box
                                      component="img"
                                      sx={{
                                        height: "auto",
                                        display: "block",
                                        overflow: "hidden",
                                        width: "100%",
                                        borderRadius: 1,
                                      }}
                                      src={step.images}
                                      alt={step.title}
                                    />
                                  </Link>
                                ) : null}
                              </div>
                            ))}
                        </AutoPlaySwipeableViews>
                        <Typography>
                          {news[0] && news[0].datas[activeStep].title}
                        </Typography>
                      </CardContent>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </Box>
      </ThemeProvider>
      <Footer />
    </div>
  );
}
