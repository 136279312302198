import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import axios from "../../utils/Axios";
import { useAlert } from "react-alert";

export default function CustomeFieldDialog({saahe_id,customList}) {
  const alert = useAlert();
  const [open, setOpen] = React.useState(false);
  const [title, set_title] = React.useState("");
  const [desc, set_desc] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const addcustom = () => {
      let formdata = new FormData();
      formdata.append("id", saahe_id);
      formdata.append("title", title);
      formdata.append("description", desc);
      axios(`Home/add_custom_fields`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            alert.success(res.data.message);
            customList(saahe_id);
            setOpen(false);
          }
        })
        .catch((err) => console.log(err));
      
  };

  return (
    <div>
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        Add Custome Field
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Custome Field </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add Custome Field menu and description here, and then click save.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="New Field Name"
            fullWidth
            variant="standard"
            value={title}
            onChange={(e) => set_title(e.target.value)}
          />
          <TextField
          multiline
            minRows={4}
            margin="dense"
            id="Description"
            label="New Field Description"
            fullWidth
            variant="standard"
            value={desc}
            onChange={(e) => set_desc(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={addcustom}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
