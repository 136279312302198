import React from "react";
import { useParams } from "react-router-dom";
import {Box,Typography,Divider,ListItemText,Grid,} from "@mui/material/";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import axios from "../../utils/Axios"; //import axios config
import CustomeFieldDialog from "./CustomeFieldDialog";
import CustomeFieldDialogEdit from "./CustomeFieldDialogEdit";


export default function MyAccountEditCustomeFields(props) {

  const [open2, setOpen2] = React.useState(false);
  const [loading,setLoading] = React.useState(false);
  const handleClose2 = () => {
    setOpen2(false);
  };

  let params = useParams();
  const [saahe_id] = React.useState(params.id ? params.id: "");
  
  const [customfields, setCustomlist] = React.useState([]);

  React.useEffect(() => {
    customList(saahe_id);
  }, []);


  const customList = (saahe_id) => {
    setLoading(true);
    axios(`Home/get_custom_fields?id=${saahe_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCustomlist(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Grid container item spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" color="GaryText">
            Custom Fields
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign="end">
            <CustomeFieldDialog saahe_id={saahe_id} customList={customList}/>
          </Typography>
        </Grid>

        <Grid item xs={12} mb={2}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {customfields && customfields.length > 0 ? (
            <div>
              <Grid item xs={12}>
                {customfields &&
                  customfields.map((value, index) => {
                    return (
                      <Grid container spacing={1} py={1}>
                        <Grid item xs={4} sm={2} md={3}>
                          <ListItemText primary={value.title} />
                        </Grid>
                        <Grid item xs={8} sm={8} md={7}>
                          <ListItemText primary={value.description} />
                        </Grid>
                        <Grid item xs={2} sm={1} md={1}>
                          <CustomeFieldDialogEdit custom_id={value.id} saahe_id={saahe_id} type={1} customList={customList}/>
                        </Grid>
                        <Grid item xs={2} sm={1}>
                          <DeleteConfirmDialog type="custom" id={value.id} Lists={customList}/>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            </div>
          ) : (
            <Box className="EducationProgramsContainer">
              <Box p={1} className="EducationDetailPrograms_bg cp">
                <Box p={1}>
                  <Typography
                    sx={{ mt: 1.5 }}
                    gutterBottom
                    variant="subtitle2"
                    component="div"
                  >
                    No Data Found
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
