import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import { useParams } from "react-router-dom";
import React, { useState, useRef } from "react";
import JoditEditor from "jodit-react";
import axios from "../../utils/Axios"; //import axios config
import { useAlert } from "react-alert";

export default function MyAccountEditOverview() {
    const alert = useAlert();
    const editor = useRef(null);
    const [overview, setOverview] = useState("");
    const config = {
      readonly: false,
      height: 400,
    };

    let params = useParams();

    const [saahe_id] = React.useState(params.id ? params.id: "");

    React.useEffect(() => {
      editPost();
    });


    const editPost = () => {
      axios(`Saahe/get_own_organization_profile?Id=${saahe_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error === true) {
            
          } else {
            setOverview(res.data.data[0].description);
          }
        })
        .catch((err) => console.log(err));
    };

    const overviewsubmit = (event) => {
      event.preventDefault();
      let formdata = new FormData();
      formdata.append("id", saahe_id);
      formdata.append("overview", overview);
      axios(`Saahe/update_overview`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.status) {
            alert.success(res.data.message);
          } else {
            alert.error(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    };

  return (
    <div>
          <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            p: 1,
          }}
        >
          <Grid item xs={12} md={6}>
            <Typography variant="h6" color="GaryText">
              Overview
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography textAlign="end">
              <Button
                variant="contained"
                color="primary"
                onClick={overviewsubmit}
              >
              Save
            </Button>
          </Typography>
        </Grid>
      </Box>
      <Divider fullwidth /> 
      <div className="TextEditor">
      <JoditEditor
        ref={editor}
        value={overview}
        config={config}
        onBlur={(e) => {
          setOverview(e);
        }}
        onChange={(newContent) => {}}
      />
    </div>


    </div>
  )
}
