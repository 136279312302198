import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import axios from "../utils/Axios"; //import axios config
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  MeetCareerExpertsCard: (hover) => ({
    width: "100%",
    height: "100%",
    background: "#FFF",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px #00000029",
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0px 0px 10px #00000069",
      //  transform: "scale(1.1)",
    },
  }),
}));

export default function DevelopersCarousel() {
  const classes = useStyles();
  const [active, setaAtive] = useState(0);

  const history = useNavigate();
  const [companies, setCompaniesList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    topCompaniesList();
  }, []);

  const topCompaniesList = () => {
    setLoading(true);
    axios(`Home/topCompanies`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCompaniesList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const CompanyView = (id) => {
    history(`/Detail/${id}`);
  };

  const viewallexperts = (id, institute_type) => {
    history.push({
      pathname: `/Listing`,
      search: `?location=${id}&institute_type=${institute_type}`,
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <Box className="caro_bg" sx={{ bgcolor: "#fff", pt: "48px", pb: "48px" }}>
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              p: 1,
              overflow: "hidden",
            }}
          >
            <Box sx={{ p: 1 }}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                color="#1F3A57"
              >
                Top Companies
              </Typography>
            </Box>
          </Box>

          <Box width="100%">
            <Carousel
              responsive={responsive}
              rightChevron={<KeyboardArrowRight />}
              leftChevron={<KeyboardArrowLeft />}
            >
              {companies && companies.map((value, index) => {
                return (
                <Box className="developers_card">
                  <CardMedia
                    component="img"
                    image={value.logo}
                    alt={value.title}
                    onClick={() => CompanyView(value.id)}
                  />
                </Box>
                  );
                })}
            </Carousel>
          </Box>
        </Container>
      </Box>
    </div>
  );
}
