import React from "react";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import {Container,Grid,Box,Button,IconButton,Tooltip,Typography,Avatar} from "@mui/material";
import { Divider, CardMedia, createTheme, ThemeProvider } from "@mui/material";
import axios from "../../utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { styled } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SuggestedGroupCarousel from "./SuggestedGroupCarousel";
import CreateNewPostDialog from "./CreateNewPostDialog";
import UserPost from "./UserPost";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "2rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
    fontWeight: "600",
  },
};
theme.typography.h4 = {
  fontSize: "1.5rem",
  fontWeight: "400",
  "@media (min-width:600px)": {
    fontSize: "2rem",
    fontWeight: "200",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.3rem",
    fontWeight: "400",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.3rem",
    fontWeight: "400",
  },
};

export default function CommuniteesDetail() {
  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);
  const [saahe, setSaahe] = React.useState({});
  const params = useParams();
  const history = useNavigate();
  const [saaheId, setSaaheId] = React.useState(params && params.id);
  const [posts, setPosts] = React.useState([]);
  React.useEffect(() => {
    setSaaheId(params && params.id ? params.id : "");
  }, [params]);
  React.useEffect(() => {
    getGroupProfile();
    getSuggestGroups(5);
    getGroupusers(1);
  }, [saaheId]);
  const getGroupProfile = () => {
    setLoading(true);
    axios(`Saahe/get_group_details?id=${saaheId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          window.scrollTo(0, 0);
          setSaahe(res.data.data[0]);
          setPosts(res.data.data[0].posts);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const [expanded, setExpanded] = React.useState(false);
  const [groups, setGroups] = React.useState([]);
  const [groupusers, setGroupuers] = React.useState([]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (expanded === "false") {
      getGroupusers(1);
    } else {
      getGroupusers(10);
    }
  };

  const getSuggestGroups = () => {
    axios(`Saahe/GroupLists?group_id=${saaheId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {

        } else {
          setGroups(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };


  const getGroupusers = (limit) => {
    axios(`Saahe/get_group_users?group_id=${saaheId}&limit=${limit}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {

        } else {
          setGroupuers(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };


  const joingroup = (jobId) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      formdata.append("jobId", jobId);
      axios(`Saahe/joinGroup`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            alert.success(res.data.message);
            getGroupProfile();
          }
        })
        .catch((err) => console.log(err));
    } else {
      history({
        pathname: "/login",
        state: { data: true },
      });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Header />
      <Box sx={{ bgcolor: "#63AB9D" }}>
        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 1.9)",
            }),
            spinner: (base) => ({
              ...base,
              width: "75px",
              "& svg circle": {
                stroke: "#025b95",
              },
            }),
          }}
          spinner
          text="Loading ..."
        >
          <Container>
            <Box sx={{ flexGrow: 1, my: 3, mt: 9 }}>
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs="auto">
                  <Link to="/">
                    <CardMedia
                      component={"img"}
                      sx={{
                        width: 170,
                        height: 170,
                        p: 1,
                        m: 1,
                        borderRadius: 100,
                        border: "5px solid #fff",
                        bgcolor: "#fff",
                      }}
                      src={saahe.logo}
                      alt=""
                      to="/home"
                    />
                  </Link>
                </Grid>
                <Grid item xs={12} sm={5} md={4.5} lg={5.5}>
                  <Grid container spacing={3} direction="column">
                    <Grid item xs={12}>
                      <Typography
                        textOverflow="ellipsis"
                        overflow="hidden"
                        maxWidth="600px"
                        variant="h4"
                        gutterBottom
                        component="div"
                        color="white"
                        startIcon={<LocationOnIcon />}
                      >
                         {saahe.title} 
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ color: "white", alignItems: "center" }}>
                        <Typography
                          color="white"
                          variant="standard"
                          startIcon={<LocationOnIcon />}
                        >
                          {saahe.description}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={4.5}
                  lg={4}
                  sx={{ flexWrap: "nowrap" }}
                >
                  <Grid
                    container
                    direction="column"
                    flexWrap="nowrap"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs sx={{ color: "white" }}>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="h4"
                              component="div"
                              color="white"
                            >
                             {posts.length}
                            </Typography>
                          </ThemeProvider>
                          <Typography
                            variant="subtitle1"
                            component="div"
                            color="white"
                          >
                            Post
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Divider
                            sx={{
                              width: 2,
                              height: 28,
                              m: 0.5,
                              pl: 0,
                              bgcolor: "white",
                            }}
                            orientation="vertical"
                          />
                        </Grid>
                        <Grid item xs>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="h4"
                              component="div"
                              color="white"
                            >
                              {saahe.user_count}
                            </Typography>
                          </ThemeProvider>
                          <Typography
                            variant="subtitle1"
                            component="div"
                            color="white"
                          >
                            Members
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </LoadingOverlay>
      </Box>

      <Box sx={{ bgcolor: "background.paper" }}>
        <Container>
          <LoadingOverlay
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(255, 255, 255, 1.9)",
              }),
              spinner: (base) => ({
                ...base,
                width: "75px",
                "& svg circle": {
                  stroke: "#025b95",
                },
              }),
            }}
            spinner
            text="Loading ..."
          >
            <Grid container spacing={3} pb={4}>
              <Grid item xs={12} sm={12} md={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box bgcolor="grey.100" borderRadius={4}>
                      <Grid container spacing={0} p={2}>
                        <Grid item xs={12}>
                        {saahe.group_exist ===1 ? (
                          <CreateNewPostDialog getPosts={getGroupProfile}/>
                        ) : (
                        <Button sx={{ width: "max-content", alignSelf: "center" }} 
                                variant="contained" color="primary" size="small" 
                                onClick={() => joingroup(saahe.id)}>
                          Join Group 
                        </Button>
                      )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box bgcolor="grey.100" borderRadius={4}>
                      <Grid container spacing={0} py={2}>
                        <Grid item xs={12}>
                          <SuggestedGroupCarousel groups={groups}/>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Box bgcolor="grey.100" borderRadius={4}>
                      <Grid container spacing={0} py={2}>
                        <Grid item xs={12}>
                          <ExplorEvents />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Grid item xs={12}>
                  <Box bgcolor="grey.100" borderRadius={4}>
                    <Grid container spacing={0} p={2}>
                      <Grid item xs={12}>
                        <UserPost posts={posts} getGroupProfile={getGroupProfile}/>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} height="fit-content">
                    <Box bgcolor="grey.100" borderRadius={4}>
                      <Grid container spacing={0} p={2}>
                        <Grid item xs={12}>
                          <div>
                            <Box
                              display="flex"
                              className="bg_light"
                              borderRadius="8px 8px 0px 0px"
                              m={0}
                              marginBottom="0"
                              paddingBottom="0"
                              width="100%"
                            >
                              <Box width="100%">
                                <Box
                                  width="100%"
                                  display="flex"
                                  paddingBottom="1rem"
                                  paddingTop="0"
                                >
                                  <Button>Members</Button>
                                </Box>
                              </Box>
                              <Box flexShrink={1}>
                                <Box
                                  width="100%"
                                  display="flex"
                                  >
                                  <Tooltip title="Show all GROUPS">
                                    <ExpandMore
                                      expand={expanded}
                                      onClick={handleExpandClick}
                                      aria-expanded={expanded}
                                      aria-label="show more"
                                    >
                                      <ExpandMoreIcon />
                                    </ExpandMore>
                                  </Tooltip>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              className="bg_light"
                              borderRadius="0px 0px 8px 8px"
                              m={0}
                              marginTop="0px"
                              width="100%"
                            >
                              <Box width="100%">
                              {groupusers && groupusers.map((user, index) => {
                                if(index < 5 ){
                                return (
                                <Box display="flex" maxWidth="300px">
                                  <Box className="VisitorProfile_bg">
                                    <Box sx={{ maxWidth: 60, height: 60 }}>
                                      <Avatar src={user.profile_image} />
                                    </Box>
                                  </Box>
                                  <Box px={1} flexGrow={1}>
                                    <Typography
                                      variant="subtitle2"
                                      color="Primary"
                                      gutterBottom
                                    >
                                      {user.name}
                                    </Typography>
                                  </Box>
                                </Box>
                                );
                                  }
                                })}

                                <Collapse
                                  in={expanded}
                                  timeout="auto"
                                  unmountOnExit
                                  >
                                  {groupusers && groupusers.map((user, index) => {
                                    if(index >= 5 ){
                                    return (
                                  <Box>
                                    <Box width="100%">
                                      <Box display="flex" maxWidth="300px">
                                        <Box className="VisitorProfile_bg">
                                          <Box
                                            sx={{ maxWidth: 60, height: 60 }}
                                          >
                                            <Avatar src={user.profile_image} />
                                          </Box>
                                        </Box>
                                        <Box px={1} flexGrow={1}>
                                          <Typography
                                            variant="subtitle2"
                                            color="Primary"
                                            gutterBottom
                                          >
                                            {user.name}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                  );
                                    }
                                  })}
                                </Collapse>
                              </Box>
                            </Box>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={12} height="fit-content">
                    <Box bgcolor="grey.100" borderRadius={4}>
                      <Grid container spacing={0} p={2}>
                        <FeaturedUniversities />
                      </Grid>
                    </Box>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </LoadingOverlay>
        </Container>
      </Box>
      <Footer />
    </div>
  );
}
