import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Autocomplete, FormControl, Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import axios from "../utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import JoditEditor from "jodit-react";
import image from "../images/skeleton/logo_skeleton.webp";
import banner from "../images/skeleton/banner500.webp";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Paper } from "@mui/material";

const useStyle = makeStyles({
  image: {
    borderRadius: 10,
    maxWidth: "100%",
  },
  image2: {
    borderRadius: 10,
    maxWidth: "100%",
  },
});

const Input = styled("input")({
  display: "none",
});

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        color: "white",
        m: 0.5,
        borderRadius: 1,
        textAlign: "center",
        fontSize: "1rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default function MyAccountManageListingRegister({
  setcheckedItem,
  checkedItem,
  setList,
  list,
  setLogo,
  setBanner, 
  setDescription,
  setInsti_cities
}) {
  
  const classes = useStyle();
  var loadFile = (event) => {
    if (event.target.files) {
      setUploadImage(URL.createObjectURL(event.target.files[0]));
      setLogo(event.target.files[0]);
    }
  };

  var loadFile2 = (event) => {
    if (event.target.files) {
      setUploadBanner(URL.createObjectURL(event.target.files[0]));
      setBanner(event.target.files[0]);
    }
  };

  const [cities, setCities] = React.useState([""]);
  const [uploadImage, setUploadImage] = React.useState("");
  const [uploadBanner, setUploadBanner] = React.useState("");
  const [content, setContent] = useState("");
  const editor = useRef(null);
  const config = {
    readonly: false,
    height: 300,
  };
  const handleUpdate = (event) => {
    const editorContent = event;
    setContent(editorContent);
    setDescription(editorContent);
  };

  React.useEffect(() => {
    getCities();
  }, []);

  const getCities = () => {
    axios(`Saahe/getCities`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCities(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  console.log(checkedItem);

  return (
    <div>
      <Grid container item spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            required
            label="Title"
            value={list.insti_name}
            onChange={(e) =>
              setList({ ...list, insti_name: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            label="Email"
            fullWidth
            size="small"
            value={list.insti_email}
            onChange={(e) =>
              setList({ ...list, insti_email: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            required
            label="Contact Number"
            value={list.insti_mobile}
            onChange={(e) =>
              setList({ ...list, insti_mobile: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            required
            label="Address"
            value={list.insti_address}
            onChange={(e) =>
              setList({ ...list, insti_address: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            label="Zip Code / Postal Code"
            value={list.insti_zipcode}
            onChange={(e) =>
              setList({ ...list, insti_zipcode: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            required
            label="Website"
            value={list.insti_website}
            onChange={(e) =>
              setList({ ...list, insti_website: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            required
            label="Location"
            value={list.insti_location}
            onChange={(e) =>
              setList({ ...list, insti_location: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            required
            label="Founded Year"
            value={list.insti_year}
            onChange={(e) =>
              setList({ ...list, insti_year: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            required
            label="Latitude"
            value={list.insti_latitude}
            onChange={(e) =>
              setList({ ...list, insti_latitude: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            required
            label="Longitude"
            value={list.insti_longitude}
            onChange={(e) =>
              setList({ ...list, insti_longitude: e.target.value })
            }
          />
        </Grid>
       
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            required
            label="Map Link"
            value={list.map_link}
            onChange={(e) =>
              setList({ ...list, map_link: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small">
            <Autocomplete
              size="small"
              options={cities}
              getOptionLabel={(option) => option.name}
              onChange={(e, value) => {
                setList({
                  ...list,
                  insti_city: value && value.id,
                });
              }}
              autoHighlight
              renderInput={(params) => (
                <TextField {...params} label="City" value={cities} />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper style={{ width: "100%" }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography variant="caption">Logo or Profile image </Typography>
              <input
                type="file"
                accept="image/*"
                name="image"
                id="file"
                onChange={loadFile}
                style={{ display: "none" }}
              />
              <img
                src={uploadImage ? uploadImage : image}
                className={classes.image}
                alt="logo"
                id="output"
                width="auto"
                maxWidth="100%"
                height="135px"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" padding="5px 20px">
              <label htmlFor="file" style={{ cursor: "pointer" }}>
                <PhotoCameraIcon />
              </label>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper style={{ width: "100%" }}>
            <Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Typography variant="caption">Business Banner </Typography>
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  id="file2"
                  onChange={loadFile2}
                  style={{ display: "none" }}
                />
                <img
                  src={uploadBanner ? uploadBanner : banner}
                  className={classes.image}
                  id="output2"
                  alt="banner"
                  width="auto"
                  maxWidth="100%"
                  height="135px"
                  borderRadius={3}
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" padding="5px 20px">
                <label htmlFor="file2" style={{ cursor: "pointer" }}>
                  <PhotoCameraIcon />
                </label>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} py={2}>
          <Box display="flex" alignItems="center" py={1}>
            <Typography variant="subtitle2">Overview</Typography>
            <Tooltip title=" Institute Information">
              <HelpOutlineIcon sx={{ pl: 1, color: "#2D5DAC" }} size="small" />
            </Tooltip>
          </Box>
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            onBlur={handleUpdate}
            onChange={(newContent) => {}}
          />
        </Grid>
      </Grid>

    </div>
  )
}
