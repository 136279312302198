import React from "react";
import Footer from "../footer/Footer";
import AgroStories from "./AgroStories";
import Banner from "./Banner";
import DevelopersCarousel from "./DevelopersCarousel";
import ExploreNearby from "./ExploreNearby";
import OpportunitiesCarousel from "./OpportunitiesCarousel";
import OurExperts from "./OurExperts";
import OurMission from "./OurMission";
import CommuniteesCarousel from "./CommuniteesCarousel";

export default function Home() {
  return (
    <div>
      <Banner />
      <ExploreNearby />
      <OurMission />
      <OpportunitiesCarousel />
      <AgroStories />
      <DevelopersCarousel />
      <OurExperts />
      <CommuniteesCarousel />
      <Footer />
    </div>
  );
}
