import {Box,Container,Typography,Grid,Paper,ButtonBase,styled,ThemeProvider,CardMedia,
  IconButton,Tooltip,CardContent,} from "@mui/material";
import React from "react";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "../../utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import parse from "html-react-parser";
import { useTheme } from "@mui/material/styles";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        fontSize: "0.875rem",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

window.scrollTo(0, 0);
export default function StoriesDetail(props) {
  const [loading, setLoading] = React.useState(false);
  const params = useParams();
  const [articles, setArticles] = React.useState({});
  const [articeId, setArticeId] = React.useState(params && params.id);
  const theme = useTheme();
  const [news, setNews] = React.useState([]);

  React.useEffect(() => {
    newsList();
    window.scrollTo(0, 0);
  }, []);

  const newsList = () => {
    setLoading(true);
    axios(`Article/get_all_news`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setNews(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    setArticeId(params && params.id ? params.id : "");
  }, [params]);

  React.useEffect(() => {
    getbooks();
  }, [articeId]);
  const getbooks = () => {
    setLoading(true);
    axios(`Article/get_article_info?articeId=${articeId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          window.scrollTo(0, 0);
          setArticles(res.data.data[0]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Header />
      <ThemeProvider theme={theme}>
        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 1.9)",
            }),
            spinner: (base) => ({
              ...base,
              width: "75px",
              "& svg circle": {
                stroke: "#025b95",
              },
            }),
          }}
          spinner
          text="Loading ..."
        >
          <Box
            className="caro_bg"
            sx={{ bgcolor: "#f9f9f9", pt: "110px", pb: "48px" }}
          >
            <Container maxWidth="xl">
              <Paper
                sx={{
                  p: 2,
                  margin: "auto",
                  flexGrow: 1,
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm container>
                    <Grid
                      item
                      xs={12}
                      md={8}
                      container
                      direction="column"
                      spacing={2}
                    >
                      <Grid item xs>
                        <Typography variant="body2" gutterBottom>
                          Posted – {articles.created}
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h6"
                          color="primary"
                          component="div"
                        >
                          {articles.title}
                        </Typography>
                        <ButtonBase>
                          <CardMedia
                            component="img"
                            width="100%"
                            height="auto"
                            sx={{
                              borderRadius: "5px",
                              border: "1px solid #f5f5f5",
                            }}
                            image={articles.image}
                            alt={articles.title}
                          />
                        </ButtonBase>
                      </Grid>

                      <Grid item xs>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          gutterBottom
                        >
                          {articles.description && parse(articles.description)}
                        </Typography>

                        <Link to="/">
                          <Tooltip title="  Back to Stories">
                            <Typography display="inline-block">
                              <IconButton>
                                <ArrowBackIcon />
                              </IconButton>
                            </Typography>
                          </Tooltip>

                          <Typography
                            display="inline-block"
                            variant="body2"
                            component="div"
                          >
                            Back to Stories
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      container
                      direction="column"
                      spacing={2}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <Item>
                            <LoadingOverlay
                              active={loading}
                              styles={{
                                overlay: (base) => ({
                                  ...base,
                                  background: "rgba(255, 255, 255, 1.9)",
                                }),
                                spinner: (base) => ({
                                  ...base,
                                  width: "75px",
                                  "& svg circle": {
                                    stroke: "#025b95",
                                  },
                                }),
                              }}
                              spinner
                              text="Loading ..."
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={11} md={11}>
                                  <Typography variant="h6" px={2} py={2}>
                                    Related Stories
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  display={{ xs: "none", md: "block" }}
                                >
                                  <Link to="/">
                                    <Tooltip title=" Back to Stories">
                                      <Typography display="inline-block">
                                        <IconButton>
                                          <ArrowBackIcon />
                                        </IconButton>
                                      </Typography>
                                    </Tooltip>
                                  </Link>
                                </Grid>
                              </Grid>

                              {news[0] &&
                                news[0].datas.map((data, index) => (
                                  <Grid
                                    container
                                    spacing={1}
                                    mb={0}
                                    key={index}
                                  >
                                    <Grid item xs={12} sm container>
                                      <CardContent>
                                        <Grid item>
                                          <Link
                                            to={`/StoriesDetail/${data.id}`}
                                          >
                                            <ButtonBase
                                              sx={{ width: 100, height: 68 }}
                                            >
                                              <Img
                                                alt="complex"
                                                src={data.images}
                                                sx={{
                                                  borderRadius: 1,
                                                  border: "Solid 1px #f5f5f5",
                                                }}
                                              />
                                            </ButtonBase>
                                          </Link>
                                        </Grid>
                                      </CardContent>
                                      <Grid item xs={12} sm container>
                                        <Link to={`/StoriesDetail/${data.id}`}>
                                          <Grid item xs>
                                            <CardContent sx={{ px: "0px" }}>
                                              <Typography
                                                variant="subtitle2"
                                                component="div"
                                                fontWeight={600}
                                              >
                                                {parse(
                                                  data.title.substring(0, 100) +
                                                    "."
                                                )}
                                              </Typography>
                                              <Typography
                                                  className="text_bg"
                                                  variant="caption"
                                                  color="gray"
                                                  component="div"
                                                >
                                                  {parse(data.description.substring(0, 60) + " ")}
                                                </Typography>        
                                              <Typography
                                                variant="body2"
                                                color="textSecondary"
                                              >
                                                Paperback – {data.created}
                                              </Typography>
                                            </CardContent>
                                          </Grid>
                                        </Link>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))}
                            </LoadingOverlay>
                          </Item>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Container>
          </Box>
        </LoadingOverlay>
      </ThemeProvider>
      <Footer />
    </div>
  );
}
