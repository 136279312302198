import React from "react";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
//import LinkedInIcon from "@material-ui/icons/LinkedIn";
//import YouTubeIcon from "@material-ui/icons/YouTube";
//import FacebookIcon from "@material-ui/icons/Facebook";
//import InstagramIcon from "@material-ui/icons/Instagram";
//import TwitterIcon from "@material-ui/icons/Twitter";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme();

theme.typography.h4 = {
  fontSize: "1.2rem",
  fontWeight: "100",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {" © "}
      {new Date().getFullYear()}
      <Link color="#676767" href="https://www.gligx.com/" target="_blank">
        SAAEH
      </Link>{" "}
    </Typography>
  );
}

export default function Footer() {
  return (
    <div>
          <Box sx={{ bgcolor: "#F7F7F7", pt: "48px", pb: "48px" }}>
        <Container maxWidth="xl">
          <Box display="flex" justifyContent="center" alignItems="center">
            <ThemeProvider theme={theme}>
              <Typography variant="h4" gutterBottom>
                Subscribe For The Latest Updates
              </Typography>
            </ThemeProvider>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 1,
            }}
            className="footer_bg"
          >
            <Box className="subscribe_text">
              <TextField
                id="outlined-basic"
                placeholder="Enter your email address"
                variant="standard"
                fullWidth
              />
            </Box>
            <Box className="subscribe_btn">
              <Button color="inherit">Submit</Button>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center"></Box>
          <Box
            className="footer_link"
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            marginTop="30px"
            p={1}
          >
            <Box p={1}>
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                color="#572672"
              >
                ABOUT SAAEH
              </Typography>
                <Typography variant="p" component="p" underline="none">
                  Who We Are
                </Typography>
            </Box>
            <Box p={1}>
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                color="#572672"
              >
                News and Articles
              </Typography>
                <Typography variant="p" component="p" underline="none">
                  LifeStyle
                </Typography>
                <Typography variant="p" component="p" underline="none">
                  Business
                </Typography>
                <Typography variant="p" component="p" underline="none">
                  Health
                </Typography>
            </Box>
            <Box p={1}>
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                color="#572672"
              >
                Top Institutions
              </Typography>
                <Typography variant="p" component="p" underline="none">
                  University
                </Typography>
                <Typography variant="p" component="p" underline="none">
                  Training Centers
                </Typography>
                <Typography variant="p" component="p" underline="none">
                  Tutors
                </Typography>
            </Box>

            <Box p={1}>
              <Typography variant="h6" component="h2" gutterBottom>
                Join with Sa`aeh
              </Typography>
                <Typography variant="p" component="p">
                  List on Sa`aeh
                </Typography>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box bgcolor="#eeeeee">
        <Container>
          <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <Box sx={{ p: 1 }}>
            <Typography variant="body2" color="textSecondary">
            © 2022 SAAEH
    </Typography>
            </Box>
            <Box sx={{ p: 1 }}>
              Inc - Privacy
            </Box>
            <Box sx={{ p: 1 }}>
              <Divider
                color="white"
                sx={{ height: 28, m: 0.5 }}
                orientation="vertical"
              />
            </Box>
            <Box sx={{ p: 1 }}>
               Terms
            </Box>
            <Box sx={{ p: 1 }}>
              <Divider
                color="white"
                sx={{ height: 28, m: 0.5 }}
                orientation="vertical"
              />
            </Box>
            <Box sx={{ p: 1, flexGrow: 1 }}>
             Sitemap
            </Box>
            <Box sx={{ p: 1 }}>
              <Box p={1}>
                <Box p={1} className="footer_social_bg">
                  <Link
                    className="fsi"
                    target="_blank"
                    to="https://www.facebook.com"
                  >
                    <FacebookIcon fontSize="large" />
                  </Link>
                  <Link
                    className="fsi"
                    target="_blank"
                    to="https://www.facebook.com"
                  >
                    <InstagramIcon fontSize="large" />
                  </Link>
                  <Link
                    className="fsi"
                    target="_blank"
                    to="https://www.facebook.com"
                  >
                    <TwitterIcon fontSize="large" />
                  </Link>
                  <Link
                    className="fsi"
                    target="_blank"
                    to="https://www.facebook.com"
                  >
                    <LinkedInIcon fontSize="large" />
                  </Link>
                  <Link
                    className="fsi"
                    target="_blank"
                    to="https://www.facebook.com"
                  >
                    <YouTubeIcon fontSize="large" />
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      

      


      
    </div>
  )
}
