import React, { useContext } from "react";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import Container from "@mui/material/Container";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { createTheme } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import axios from "../../utils/Axios"; //import axios config
import parse from "html-react-parser";
import { Grid, IconButton, Tooltip } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SchoolIcon from "@mui/icons-material/School";
import CallIcon from "@mui/icons-material/Call";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ExpertDetailDialog from "./ExpertDetailDialog";
import ExpertDetailAccordion from "./ExpertDetailAccordion";

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "2rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
    fontWeight: "600",
  },
};
theme.typography.h4 = {
  fontSize: "1.5rem",
  fontWeight: "400",
  "@media (min-width:600px)": {
    fontSize: "2rem",
    fontWeight: "200",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.3rem",
    fontWeight: "400",
  },
};

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function ExpertDetail(props) {
  const [value, setValue] = React.useState(0);
  const alert = useAlert();
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [saahe, setSaahe] = React.useState({});
  const [showNumber, setshowNumber] = React.useState(0);
  const [showMore, setShowMore] = React.useState(false);
  const [showButton, setshowButton] = React.useState(0);

  const params = useParams();
  const [Id, setId] = React.useState(params && params.id);

  //const { loginopen, setLoginOpen } = useContext(AuthContext);

  React.useEffect(() => {
    getProfile();
  }, [Id]);

  const getProfile = () => {
    setLoading(true);
    axios(`Saahe/get_expert_profile?Id=${Id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          setSaahe(res.data.data);
          setValue(res.data.data.rating);
          if (
            res.data.data.description &&
            res.data.data.description.length > 500
          ) {
            setshowButton(1);
          }
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const ShowNumber = () => {
    setshowNumber(1);
  };

  const apply = (courseId, request_type) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      formdata.append("courseId", courseId);
      formdata.append("request_type", request_type);
      axios(`Saahe/apply`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            getProfile();
            alert.success(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    } else {
      history.push({
        pathname: "/signin",
        state: { data: true },
      });
    }
  };

  const [ratings, setRating] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  //const [raings, setRating] = React.useState([]);

  const getrating = (e, page, rowsPerPage) => {
    setLoading(true);
    axios(
      `Saahe/get_all_detail_rating?id=${Id}&page=${page}&per_page=${rowsPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          setLoading(false);
          setRating(res.data.data);
          setTotal(res.data.total);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <Header />
      <Box className="ExpertDetailBox">
        <Container>
          <Box sx={{ flexGrow: 1, mt: 11 }}>
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={4} md={3}>
                <Link to="/">
                  <CardMedia
                    component={"img"}
                    sx={{
                      width: 170,
                      height: 170,
                      p: 1,
                      m: 1,
                      borderRadius: 100,
                      border: "5px solid #fff",
                      bgcolor: "#fff",
                    }}
                    src={saahe.logo}
                    alt=""
                    to="/home"
                  />
                </Link>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Typography
                  textOverflow="ellipsis"
                  overflow="hidden"
                  variant="h4"
                  gutterBottom
                  component="div"
                  color="white"
                  starticon={<LocationOnIcon />}
                >
                  {saahe.name && saahe.name}
                </Typography>
                <Box sx={{ color: "white", alignItems: "center" }}>
                  <IconButton aria-label="upload picture" component="span">
                    <LocationOnIcon sx={{ color: "white" }} />
                  </IconButton>
                  <Typography
                    color="white"
                    variant="standard"
                    starticon={<LocationOnIcon />}
                  >
                    {saahe.city && saahe.city}
                  </Typography>
                </Box>
                 <ExpertDetailDialog saaheId={Id} ratingvalue={value} rateType={1} rating_count={saahe.rating_count}  getProfile={getProfile} getrating={getrating}/>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box sx={{ bgcolor: "#f9f9f9", py: "1rem" }}>
        <Container>
          <Typography variant="body2" gutterBottom>
            <div role="presentation" onClick={handleClick}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to="/">
                  <Typography>Home</Typography>
                </Link>
                <Link to="#">
                  <Typography>{saahe.name && saahe.name}</Typography>
                </Link>
              </Breadcrumbs>
            </div>
          </Typography>
        </Container>
      </Box>

      <Container>
        <Grid container spacing={3} py={3}>
          <Grid item xs={12} sm={12} md={8}>
            <Grid container spacing={3}>
              <Grid item sm={12} md={12}>
                <CardMedia
                  component="img"
                  sx={{ border: "solid 1px #f1f1f1", borderRadius: 4 }}
                  height="275"
                  Width="100%"
                  alt="Course banner"
                  image={saahe.banner}
                />
              </Grid>
              <Grid item sm={12} md={12}>
                <Typography variant="p" color="text.secondary">
                  {showMore
                    ? saahe.description && parse(saahe.description)
                    : `${saahe.short_description && saahe.short_description}`}
                  {showButton === 1 ? (
                    <Typography variant="p" color="text.secondary">
                      {showMore ? "" : ".."}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {showButton === 1 ? (
                  <Button
                    className="btn"
                    onClick={() => setShowMore(!showMore)}
                  >
                    {showMore ? "Show less" : "Show more"}
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} height="fit-content">
            <Box bgcolor="grey.100" borderRadius={4}>
              <Grid>
                <Grid p={1}>
                  {saahe.course_fee !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                            <IconButton aria-label="fees">
                              <AttachMoneyIcon />
                            </IconButton>
                            fees:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                            {saahe.course_fee}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.duration !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                            <IconButton aria-label="Duration">
                              <TimelapseIcon />
                            </IconButton>
                            Duration:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                            {saahe.duration}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.type_id !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                            <IconButton aria-label="Course Type">
                              <LibraryBooksIcon />
                            </IconButton>
                            Course Type:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                            {saahe.course_type} Weekend, Weekday classes
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.duration_type !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                            <IconButton aria-label="Course Schedule">
                              <MenuBookIcon />
                            </IconButton>
                            Course Schedule:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                            2021 - 2022
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.institution_id !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                            <IconButton aria-label="University">
                              <SchoolIcon />
                            </IconButton>
                            University:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                            {saahe.institue}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.course_fee !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                            <IconButton aria-label="Teliphone">
                              <CallIcon />
                            </IconButton>
                            Teliphone:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography
                            variant="body2"
                            gutterBottom
                            className="cp_bg"
                          >
                            {showNumber === 0 ? (
                              <span className="cp" onClick={() => ShowNumber()}>
                                Show number
                              </span>
                            ) : (
                              saahe.tel_no
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={6}
                    bgcolor="#48C851"
                    color="white"
                    p={1}
                    borderRadius="0px 0px 0px 15px"
                    textAlign="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <Typography>
                        {localStorage.getItem("data") ? (
                          saahe.apply == 0 ? (
                            <Button
                              color="inherit"
                              onClick={() => apply(saahe.id, 1)}
                            >
                              Apply
                            </Button>
                          ) : (
                            <Button
                              color="inherit"
                              onClick={() => alert.error("All ready applied!")}
                            >
                              Admission Applied
                            </Button>
                          )
                        ) : (
                          <Button
                            color="inherit"
                            //onClick={() => setLoginOpen(true)}
                          >
                            Admission
                          </Button>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    bgcolor="#434343"
                    color="white"
                    p={1}
                    borderRadius="0px 0px 15px 0px"
                    textAlign="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {localStorage.getItem("data") ? (
                      saahe.request == 0 ? (
                        <Button
                          color="inherit"
                          onClick={() => apply(saahe.id, 2)}
                        >
                          Request info
                        </Button>
                      ) : (
                        <Button color="inherit">Requested</Button>
                      )
                    ) : (
                      <Button
                        color="inherit"
                        //onClick={() => setLoginOpen(true)}
                      >
                        Request info
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Box sx={{ bgcolor: "background.paper" }}>
        <ExpertDetailAccordion saaheId={Id} setId={setId} getrating={getrating} ratings={ratings} total={total}/>
      </Box>
      <Footer />
    </div>
  );
}
