import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo_aqar.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button } from "@mui/material";
import { useAlert } from "react-alert";
import axios from "../utils/Axios"; //import axios config
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import SaaehListMenu from "./SaaehListMenu";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#000",
    },
    secondary: {
      main: "#176828",
    },
  },
});

const pages = ["Join with Sa`aeh"];
const settings = ["Logout"];

const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const history = useNavigate();
  const alert = useAlert();
  const [password, setPassword] = React.useState("");
  const [username, setUserName] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(0);
  const [keyword, setKeyword] = React.useState("");
  const [autoData, setAutoData] = React.useState([]);
  const [profile, setProfile] = React.useState([]);

  const logOut = () => {
    localStorage.removeItem("data");
    history("/");
  };

  React.useEffect(() => {
    getSearchAutocomplete();
    getProfile();
  }, [keyword]);
  React.useEffect(() => {
    getProfile();
  }, [JSON.parse(localStorage.getItem("data"))]);

  const filterSearch = () => {
    history.push({
      pathname: `/EducationLisiting`,
      search: `?keyword=${keyword}`,
    });
  };

  const getProfile = () => {
    axios(`Home/get_profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          setProfile(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getSearchAutocomplete = () => {
    if (keyword && keyword != "") {
      axios(`Home/search_autocomplete_data?keyword=${keyword}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          setAutoData(res.data.data);
        })
        .catch((err) => console.log(err));
    } else {
      setAutoData([]);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="fixed">
        <Container maxWidth="xxl">
          <Toolbar disableGutters className="headerBox">
            <Link to="/">
              <Box
                component={"img"}
                p={1}
                width={80}
                src={logo}
                alt=""
                to="/home"
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                }}
              />
            </Link>
            <Box display={{ xs: "none", md: "flex" }}>
              <SaaehListMenu />
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <Link to="/">
                <Box
                  component={"img"}
                  p={1}
                  width={80}
                  src={logo}
                  alt=""
                  to="/home"
                  onClick={handleOpenNavMenu}
                />
              </Link>
            </Box>

            <Box
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            ></Box>

            {localStorage.getItem("data") ? (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Button
                      className="header_two_menu_btn"
                      variant="standard"
                      color="#fff"
                    >
                      {profile.fullname}
                    </Button>
                    <Avatar alt="Izra" src={profile.profile_image} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem mt={2}>
                    <Avatar
                      sx={{ width: 32, height: 32, bgcolor: "white", mr: 1 }}
                      src={profile.profile_image}
                    />{" "}
                    <Link to="/MyAccount">My Account </Link>
                  </MenuItem>
                  <Divider />
                  <Box display={{ xs: "flex", md: "none" }}>
                    <SaaehListMenu />
                  </Box>
                  <Link to="/Settings">
                    <MenuItem>
                      <ListItemIcon>
                        <Settings fontSize="small" />
                      </ListItemIcon>
                      Settings
                    </MenuItem>
                  </Link>
                  <MenuItem onClick={logOut}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "flex" } }}>
                <Link to="/Login">
                  <Button sx={{ my: 2, color: "gray", display: "block" }}>
                    Join with Sa`aeh
                  </Button>
                </Link>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};
export default Header;
