import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import image2 from "../../images/logo.webp";
import axios from "../../utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import GroupWorkIcon from '@mui/icons-material/GroupWork';

const useStyle = makeStyles({
  image2: {
    borderRadius: 100,
  },
});

export default function CreateNewGroupDialog({getDoctors}) {

  let params = useParams();
  const [saahe_id] = React.useState(params.id ? params.id: "");

  const alert = useAlert();
  const [image, setImage] = React.useState("");
  const [banner, setBanner] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [Description, setDescription] = React.useState("");

  const [uploadImage, setUploadImage] = useState("");
  const [bannerImage, setBannerImage] = useState("");

  const classes = useStyle();
  var loadFile = (event) => {
    if (event.target.files[0]) {
      setUploadImage(URL.createObjectURL(event.target.files[0]));
      console.log(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };


  var loadBanner = (event) => {
    if (event.target.files[0]) {
      setBannerImage(URL.createObjectURL(event.target.files[0]));
      console.log(URL.createObjectURL(event.target.files[0]));
      setBanner(event.target.files[0]);
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("id", saahe_id);
    formdata.append("title", title);
    formdata.append("description", Description);
    formdata.append("logo", image);
    formdata.append("banner", banner);
    axios(`Saahe/create_group`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          setOpen(false);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };


  return (
    <div>
      <Button sx={{mt: 1.5 }}  fullWidth variant="contained" color="primary" onClick={handleClickOpen}>
        <GroupWorkIcon color="inherit" /> Create New Group
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Create New Group </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                size="small"
                fullWidth
                required
                label="Group Name"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
 
            
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Group description"
                multiline
                rows={4}
                value={Description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
           
          
            <Grid item xs={12} md={6} lg={6}>
              <Paper>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <input
                    type="file"
                    accept="image2/*"
                    name="image2"
                    id="file"
                    onChange={loadFile}
                    style={{ display: "none" }}
                  />
                  <Typography variant="caption">Group Logo</Typography>

                  <img
                    src={uploadImage ? uploadImage : image2}
                    className={classes.image2}
                    id="output"
                    width="200"
                    alt="test"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  padding="10px 20px"
                >
                  <label htmlFor="file" style={{ cursor: "pointer" }}>
                    <PhotoCameraIcon />
                  </label>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Paper>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <input
                    type="file"
                    accept="image2/*"
                    name="image2"
                    id="file2"
                    onChange={loadBanner}
                    style={{ display: "none" }}
                  />
                  <Typography variant="caption">Banner</Typography>

                  <img
                    src={bannerImage ? bannerImage : image2}
                    className={classes.image2}
                    id="output2"
                    width="200"
                    alt="test"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  padding="10px 20px"
                >
                  <label htmlFor="file2" style={{ cursor: "pointer" }}>
                    <PhotoCameraIcon />
                  </label>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            endIcon={<ArrowForwardIcon />}
            size="small"
            variant="contained"
            onClick={submitForm}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
