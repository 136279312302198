import React, { useState } from 'react';
import { GoogleMap, useLoadScript, InfoWindow, Marker } from '@react-google-maps/api';
import { mapStyles } from './mapStyles';
import { Box } from '@mui/system';

const containerStyle = {
  width: '100%',
  height: '60vh',
};


function Map({ lists,citylan,citylon}) {

  const center = {
    lat: parseFloat(citylan),
    lng: parseFloat(citylon)
  };

  const [selectedTienda, setSelectedTienda] = useState(null)
  const options = {
    styles: mapStyles,
    disableDefaultUI: true,

  }
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAT5K7Bpom9WsJJoPYznw1gcLVp6yrMC3w',
  });

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading maps';
  return (
    <div className="Map">
      <GoogleMap
        id='marker-example'
     //   mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        options={options}
      >

        {lists && lists.map((location) => {
          return (

            <Marker
              key={location.id}
              position={{ lat: parseFloat(location.lat), lng: parseFloat(location.lng) }}
              onClick={() => {
                setSelectedTienda(location);
              }}
            />

          )
        })}

        {selectedTienda ? (
          <InfoWindow position={{
            lat: parseFloat(selectedTienda.lat),
            lng: parseFloat(selectedTienda.lng)
          }}
            onCloseClick={() => { setSelectedTienda(null) }}
          >
            <Box width="200px">
              <img src={selectedTienda.banner} width="200px"   />
            <h4>{selectedTienda.title}</h4></Box>
          </InfoWindow>
        ) : null}


      </GoogleMap>
    </div >
  );
}

export default Map;