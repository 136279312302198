import { Box, CardMedia, Grid, Typography } from "@mui/material";
import React from "react";
import CardActions from "@mui/material/CardActions";
import Checkbox from "@mui/material/Checkbox";
import axios from "../utils/Axios"; //import axios config

export default function MyAccountPagesCategory({setcheckedItem,checkedItem}) {
  const [types, setTypes] = React.useState([]);

  const handleCheck = (index, checked) => {
    if (checked) {
      setcheckedItem(index);
    }
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const getInstitution = () => {
    axios(`Saahe/get_izra_type`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setTypes(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    getInstitution();
  }, []);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {types.map((item, index) => (
              <Grid item xs={4} textAlign="center" mb={2.5}>
                <CardMedia
                  alt="SAAEH Education Banner"
                  class="education_category_banner_bg"
                  image={item.image}
                >
                  <Box className="ECL_text_bg_100 cp">
                    <Box
                      sx={{
                        m: "5%",
                        position: "absolute",
                        left: "0",
                        bottom: "0",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Box>
                          <Typography variant="h6" color="white" gutterBottom>
                            {item.name}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="white"
                            gutterBottom
                          >
                            Create a {item.name} listing for one or more items .
                          </Typography>
                          <CardActions sx={{ justifyContent: "center" }}>
                            <Checkbox
                              id={index}
                              {...label}
                              checked={item.id === checkedItem ? true : false}
                              size="large"
                              onChange={(e) =>
                                handleCheck(item.id, e.target.checked)
                              }
                              sx={{
                                color: "#fff",
                                "&.Mui-checked": {
                                  color: "#f9f9f9",
                                },
                              }}
                            />
                          </CardActions>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </CardMedia>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
