import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import axios from "../utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button } from "@mui/material";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  TopUniversitiesCard: (hover) => ({
    width: "100%",
    height: "100%",
    background: "#F7F7F7",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px #00000029",
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0px 0px 10px #00000069",
    },
  }),
}));

export default function CommuniteesCarousel() {
  const classes = useStyles();
  const alert = useAlert();
  const [value, setValue] = React.useState(1);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchlist, setSearchList] = React.useState([]);
  const [type, setTtype] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    topLists(newValue);
  };

  const history = useNavigate();
  const [communities, setCommunitiesList] = React.useState([]);
  const [typelist, setTypeList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    topLists();
  }, []);

  //method for getting recent jobs
  const topLists = () => {
    setLoading(true);
    axios(`Saahe/all_groups?page=${page}&per_page=${rowsPerPage}&type=${type}&keyword=${searchlist}&delay=1`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCommunitiesList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const SaaheView = (id, type) => {
    if (JSON.parse(localStorage.getItem("data"))) {
    history(`/CommuniteesDetail/${id}`);
    } else {
      alert.error("Please login to view this page");
    }
  };


  const joingroup = (jobId) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      formdata.append("jobId", jobId);
      axios(`Saahe/joinGroup`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            topLists();
            alert.success(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    } else {
      history({
        pathname: "/login",
        state: { data: true },
      });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Box className="caro_bg" sx={{ bgcolor: "#fff", pt: "48px", pb: "48px" }}>
        <Container maxWidth="xl"> 
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              p: 1,
            }}
          >
            <Box sx={{ p: 1 }}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                color="textPrimary"
              >
              Communities
              </Typography>
            </Box>
            <Box sx={{ p: 1 }}>
              <Divider
                color="white"
                sx={{ height: 28, m: 0.5 }}
                orientation="vertical"
              />
            </Box>
            <Box className="cp_bg" sx={{ p: 1, flexGrow: 1 }}>
              <Link to="/Groups">
              <Typography
                className="cp"
                variant="body2"
                color="InactiveCaptionText"
                gutterBottom
                component="div"
              >
                View All
              </Typography></Link>
            </Box>
          </Box>
          <Box className="TopUniversities_carousel_bg">
            <LoadingOverlay
              active={loading}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(255, 255, 255, 1.9)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "75px",
                  "& svg circle": {
                    stroke: "#025b95",
                  },
                }),
              }}
              spinner
              text="Loading ..."
            >
              {communities.length > 0 ? (
                <Box width="100%">
                  <Carousel
                    responsive={responsive}
                    rightChevron={<KeyboardArrowRight />}
                    leftChevron={<KeyboardArrowLeft />}
                  >
                    {communities.map((value, index) => {
                      return (
                        <Box
                          className="TopUniversities_box"
                          p={2}
                        >
                          <Card
                            className={classes.TopUniversitiesCard}
                          >
                            <CardMedia
                              component="img"
                              width="100px"
                              height="100px"
                              image={value.image}
                              alt={value.title}
                              sx={{
                                borderRadius: "50%",
                                border: "10px solid #fce6e6",
                                width: "100px",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: "auto",
                                mt: 5,
                              }}
                              onClick={() => SaaheView(value.id, value.type)}
                            />

                            <Box className="cp" onClick={() => SaaheView(value.id, value.type)}>
                              <CardContent sx={{ display: "flex", flexDirection: "column"}}>
                               
                                <Tooltip Tooltip title={value.title}>
                                  <Typography
                                    Tooltip
                                    title={value.title}
                                    className="text_bg"
                                    sx={{ mt: 1.5 }}
                                    textAlign="center"
                                    variant="subtitle2"
                                    component="div"
                                  >
                                    {value.title}
                                  </Typography>
                                </Tooltip>
                                <Typography
                                variant="caption"
                                color="text.secondary"
                                className="text_bg"
                                textAlign="center"
                                gutterBottom
                              >
                                {value.user_count} Members
                              </Typography> 
                              </CardContent>
                            </Box>
                            <Box className="cp">
                              <CardContent sx={{ display: "flex", flexDirection: "column"}}>
                                <Button sx={{ width: "max-content", alignSelf: "center" }} variant="contained" color="primary" size="small" onClick={() => joingroup(value.id)}>
                                   Join Group 
                                </Button>
                              </CardContent>
                            </Box>
                          </Card>
                        </Box>
                      );
                    })}
                  </Carousel>
                </Box>
              ) : (
                <Box
                
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "650px",
                  }}
                >
                  <Box p={5} paddingBottom="105px" className="error">
                    <Typography variant="h6" color="primary">
                      <span className="error_text">No data</span> found.
                    </Typography>
                  </Box>
                </Box>
              )}
            </LoadingOverlay>
          </Box>
        </Container>
      </Box>
    </div>
  );
}
